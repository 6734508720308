@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add any custom styles below this line */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* User Notes Styles */
.slide-drawer {
  transition: transform 0.3s ease-in-out;
}

.slide-drawer-enter {
  transform: translateX(100%);
}

.slide-drawer-enter-active {
  transform: translateX(0);
}

.slide-drawer-exit {
  transform: translateX(0);
}

.slide-drawer-exit-active {
  transform: translateX(100%);
}

/* Wiki Styles */
.wiki-card {
  transition: all 0.3s ease;
}

.wiki-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Notification Styles */
.notification-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 12px 16px !important;
  transition: background-color 0.3s;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #f0f2f5;
}

.notification-content {
  display: flex;
  align-items: flex-start;
}

.notification-icon {
  font-size: 20px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f2f5;
  flex-shrink: 0;
}

.notification-text {
  flex: 1;
}

.notification-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 4px;
}

.notification-task-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 2px;
}

.notification-task-excerpt {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 4px;
}

.notification-time {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.empty-notification {
  text-align: center;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
}

/* Customize scrollbar for webkit browsers */
.notification-list::-webkit-scrollbar {
  width: 6px;
}

.notification-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.notification-list::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}

/* General styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #40a9ff;
}

.btn-secondary {
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid #d9d9d9;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #e0e0e0;
}

/* Form styles */
.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
}

.form-input:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}

.table th {
  background-color: #fafafa;
  font-weight: 500;
}

.table tr:hover {
  background-color: #fafafa;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  .page-title {
    font-size: 20px;
  }

  .section-title {
    font-size: 18px;
  }

  .card {
    padding: 16px;
  }

  .container {
    margin-top: 300px; /* Adjust this value based on your navbar height */
  }
  
  .ant-card-head-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .ant-tag-blue {
    background-color: #e6f7ff;
    border-color: #91d5ff;
    color: #1890ff;
  }

  .container {
    margin-top: 84px; /* Increased from 64px to create more space */
  }
  
  .page-title {
    margin-bottom: 24px; /* Add some space below the title */
  }
  
  .users-card {
    margin-top: 62px; /* Align the users card with the updates section */
  }
  
  .ant-card-head-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .bg-blue-50 {
    background-color: #e6f7ff;
  }

  .chat-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .chat-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .chat-exit {
    opacity: 1;
  }
  .chat-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}